<template>
  <div
    class="flex items-center justify-center"
  >
    <button
      v-if="isLoggedIn || isLoggedAdminIn"
      class="link "
      @click="logoutClick"
    >
      Sign out
    </button>
    <router-link
      v-else
      class="link"
      :to="{'/admin':admin, '/login': !admin}"
    >
      Login
    </router-link>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    admin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({ isLoggedIn: 'USER/isLoggedIn', isLoggedAdminIn: 'ADMIN/isLoggedAdminIn' })
  },
  methods: {
    logoutClick () {
      const isAdmin = this.isLoggedAdminIn
      this.$store.commit('USER/LOGOUT_USER')
      this.$store.commit('ADMIN/LOGOUT_ADMIN')
      this.$chargebee.logout()
      if (!isAdmin) {
        this.$crisp.session.reset()
        this.$crisp.chat.hide()
        this.$router.push('/login')
        this.$crisp.session.reset(true)
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style></style>
