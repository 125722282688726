<template>
  <div class="flex flex-col h-screen ">
    <!-- <the-navbar class="print:hidden" /> -->
    <div
      class="flex-1 h-full drawer drawer-mobile"
    >
      <input
        id="the-drawer"
        type="checkbox"
        class="drawer-toggle"
      >
      <div class="flex flex-col drawer-content">
        <slot />
      </div>
      <div
        class="drawer-side print:hidden"
      >
        <label
          for="the-drawer"
          class="drawer-overlay"
        />
        <the-drawer v-if="!admin" />
        <the-admin-drawer
          v-if="admin"
          :admin="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TheDrawer from '@/components/TheDrawer.vue'
import TheAdminDrawer from '@/components/AdminDrawer.vue'
export default {
  components: {
    TheDrawer,
    TheAdminDrawer
  },
  props: {
    admin: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style>

</style>
