<template>
  <ul class="overflow-y-auto drawer-color menu">
    <div class="relative capacio-logo">
      <img
        src="@/assets/capacio/login/logo.svg"
      >
    </div>
    <router-link
      :to="{name: 'Customers'}"
      v-if="$store.getters['ADMIN/isLoggedAdminIn']"
      class="my-2 ml-6"
    >
      <span class="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">Admin View</span>
    </router-link>
    <li
      :class="{'underline text-accent-green': $route.name === 'Home'}"
    >
      <router-link
        class=" link link-hover"
        :to="{name: 'Home' }"
      >
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        ><path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        /></svg>
        Home
      </router-link>
    </li>
    <li
      :class="{'underline text-accent-green': $route.fullPath.includes('/overview')}"
    >
      <router-link
        class="text-white link link-hover"

        :to="{name:'Overview', params: {page: this.$route.params.page || 0}}"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
            />
          </svg>
        </div>
        Individual Brain
      </router-link>
    </li>
    <li
      :class="{'underline text-accent-green': $route.fullPath.includes('/teams') || $route.fullPath.includes('/team-home')}"
    >
      <router-link
        class="text-white link link-hover"

        :to="ComingSoonRedirector"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
            />
          </svg>
        </div>
        Team Brain
      </router-link>
    </li>
    <li
      :class="{'underline text-accent-green':$route.fullPath.includes('/requirement-specification')}"
    >
      <router-link
        class="text-white link link-hover"
        :to="{name: 'RequirementSpecifications'}"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class=""
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
          />
        </svg>

        Requirement<br>specifications
      </router-link>
    </li>
    <!-- <li
      :class="{'underline text-accent-green':$route.fullPath.includes('/analytics')}"
    >
      <router-link
        class="text-white link link-hover"
        :to="{name: 'SelectCampaign', params: {page: this.$route.params.page || 0}}"
      >
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
          />
        </svg>

        Reports
      </router-link>
    </li> -->
    <li
      :class="{'underline text-accent-green':$route.fullPath.includes('/demo-videos')}"
    >
      <router-link
        class="text-white link link-hover"
        :to="{name: 'DemoVideos'}"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            stroke-linejoin="round"
            d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
          />
        </svg>

        Resources
      </router-link>
    </li>
    <li class="h-6" />
    <li
      v-if="subscription.inTrial"
      class="mt-auto"
    >
      <router-link
        class="flex flex-col text-white "
        :to="{name: 'Checkout', query: {fromTrial: true}}"
      >
        <div class="badge badge-lg">
          Free trial
        </div>
        {{ subscription.trialDaysRemaning }} {{ subscription.trialDaysRemaning === 1 ? 'day' : 'days' }} left
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',

  computed: {
    ...mapGetters({ subscription: 'USER/getSubscription' }),
    ComingSoonRedirector () {
      const teamBrain = { name: 'TeamHome' }
      const comingSoon = { name: 'ComingSoon' }
      const page = this.$store.state.USER.user.termRemainingTeams !== null ? teamBrain : comingSoon
      return page
    }
  }
}
</script>

<style lang="scss">
@import "./drawer.scss";
</style>
