
<template>
  <ul
    v-if="isLoggedAdminIn"
    class="overflow-y-auto bg-gray-200 menu"
  >
    <div class="capacio-logo">
      <img
        src="@/assets/capacio/login/logo.svg"
      >
    </div>
    <div class="flex mt-2 mb-2 flex-row item-center justify-center">
      <router-link
        :to="{name: 'Customers'}"
        v-if="$store.getters['ADMIN/isLoggedAdminIn']"
        class="ml-2"
      >
        <span class="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">Admin View</span>
      </router-link>
      <router-link
        to="/home"
        v-if="$store.getters['ADMIN/isLoggedAdminIn'] && $store.state.USER.user.organizationsName"
      >
        <span class="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">Home</span>
      </router-link>
    </div>
    <li>
      <hr class="h-1 bg-gray-300">
    </li>
    <li :class="{ 'underline text-accent-green': $route.name === 'Home' }">
      <router-link
        class="link link-hover"
        :to="{ name: 'Customers' }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 mr-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
          />
        </svg>

        Customer accounts
      </router-link>
    </li>
    <li>
      <hr class="h-1 bg-gray-300">
    </li>
    <li :class="{ 'underline text-accent-green': $route.name === 'Home' }">
      <router-link
        class="link link-hover"
        :to="{ name: 'LowScoreCandidates' }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 mr-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
          />
        </svg>

        Low Score Candidates
      </router-link>
    </li>
    <li>
      <hr class="h-1 bg-gray-300">
    </li>
    <li :class="{ 'underline text-accent-green': $route.name === 'Home' }">
      <router-link
        class="link link-hover"
        :to="{ name: 'Teams' }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 mr-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
          />
        </svg>

        Customer Teams
      </router-link>
    </li>
    <div class="flex flex-col mt-3 items-center">
      <div v-if="$store.getters['ADMIN/isLoggedAdminIn']">
        <div>{{ `${$store.state.ADMIN.admin.account.name}` }} <span class="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">Admin</span></div>
        <div>{{ `${$store.state.ADMIN.admin.account.username}` }}</div>
      </div>
      <div v-if="$store.state.USER.user.organizationsName">
        <div class="flex mt-3">
          <span class="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 dark:bg-blue-200 dark:text-blue-800">Login as {{ `${$store.state.USER.user.organizationsName}` }}
          </span>
          <svg
            viewBox="0 0 384.971 384.971"
            style="width: 15px"
            @click="logoutFromOrg()"
          >
            <g id="Sign_Out">
              <path
                d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z"
              />
              <path
                d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"
              />
            </g>
          </svg>
        </div>
      </div>
      <login-button
        class="mt-2 absolute bottom-8 left-6"
        :admin="admin"
      />
    </div>
    <li class="h-6" />
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginButton from './TheLoginButton.vue'

export default {
  components: {
    LoginButton
  },
  props: {
    admin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  async mounted () {
  },
  computed: {
    ...mapGetters({ isLoggedAdminIn: 'ADMIN/isLoggedAdminIn', isLoggedIn: 'USER/isLoggedIn' })
  },
  methods: {
    logoutFromOrg () {
      this.$store.commit('USER/LOGOUT_USER')
    }

  }
}
</script>

<style>
</style>
